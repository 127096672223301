import router from '@/router'
import { isTokenExpired } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import AlertService from './alert.service'

const ApiService = {
  get(resource, params, instance) {
    this.setInterceptor(instance)
    return useJwt.axiosIns.get(resource, params).catch(error => {
      this.controlErrorResponse(error, instance)
      return Promise.reject(error)
    })
  },

  post(resource, params, instance) {
    this.setInterceptor(instance)
    return useJwt.axiosIns.post(`${resource}`, params).catch(error => {
      this.controlErrorResponse(error, instance)
      return Promise.reject(error)
    })
  },

  upload(resource, formData, instance) {
    this.setInterceptor(instance)
    return useJwt.axiosIns.post(`${resource}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .catch(error => {
              this.controlErrorResponse(error, instance)
            })
  },

  download(resource, params, instance) {
    return useJwt.axiosIns({
              url: `${resource}`,
              method: 'GET',
              responseType: 'blob',
            }).catch(error => {
              this.controlErrorResponse(error, instance)
            })
  },

  update(resource, slug, params) {
    return useJwt.axiosIns.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return useJwt.axiosIns.put(`${resource}`, params)
  },

  delete(resource, params, instance) {
    this.setInterceptor(instance)
    return useJwt.axiosIns.delete(`${resource}`, params).catch(error => {
      this.controlErrorResponse(error, instance)
      return Promise.reject(error)
    })
  },

  controlErrorResponse(response, instance) {
    if (!instance.commit) instance = instance.$store
    instance.commit('setLoader', false)
    const instanceObject = instance._vm ? instance._vm : instance;
    if (response && response.status === 401) {
      router.push({ name: 'login' })
      AlertService.error(instanceObject, 'Token süresi doldu');
    } else {
      AlertService.error(instanceObject, 'Sunucudan yanıt dönmedi');
    }
  },

  setInterceptor(instance) {
    const instanceObject = instance._vm ? instance._vm : instance;
    if (router.currentRoute.path.includes('/forgot-password') || router.currentRoute.path.includes('/reset-password')) {
      if (!instance.commit) instance = instance.$store
      instance.commit('setLoader', true);
      useJwt.axiosIns.interceptors.response.use(response => {
        instance.commit('setLoader', false);
        return response;
      }, error => {
        if (error && error.statusCode === 401) {
          AlertService.error(instanceObject, error.headers['www-authenticate']);
        }
        return Promise.reject(error); 
      });
      return;
    }
    
    if (!isTokenExpired()) {
      if (!instance.commit) instance = instance.$store
      instance.commit('setLoader', true);
      useJwt.axiosIns.interceptors.response.use(response => {
        instance.commit('setLoader', false);
        return response;
      }, error => {
        if (error && error.statusCode === 401) {
          AlertService.error(instanceObject, error.headers['www-authenticate']);
        }
        return Promise.reject(error); 
      });
    } else {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      AlertService.error(instanceObject, 'Token süresi doldu');
      router.push({ name: 'login' })
    }
  },
}

export default ApiService

import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Şimdiki zamanı döndürür
 * @param {Object} options obje {type:type,value:Number}
 * @param {String} type 'm' , 'd' veya 'y'
 * @param {Number} value sayı
 */
export function getCurrentDate(args) {
  /* eslint-disable */
  let date;
  if (args) {
    const { type, value, firstDay } = args;
    if (type === 'm') date = new Date(new Date().setMonth(value));
    else if (type === 'd') {
      date = new Date(Date.now() + value*1000*60*60*24);
    } 
    else if(type === 'y') date = new Date(new Date().setFullYear(value));
    if(firstDay) {
      if(type && value)
        date = new Date(date.setDate(1));
        else {
          date = new Date(new Date().setDate(1));
      }
    }
  } else {
    date = new Date();
  }
  return date.toISOString().split('T')[0];
    /* eslint-enable */
}

export function getFirstDayOfYear() {
  return (new Date(new Date().getFullYear(), 0, 1, 3)).toISOString().split('T')[0];
}

/**
 * Veri tabanından gelen T'li zamanı T kısmından ayırıp yyyy-mm-dd geri döndürür
 * @param {String} apiDate
 * @returns string yyyy-mm-dd
 */
export function apiToDate(apiDate) {
  return apiDate.split('T')[0];
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateToDMY = (value, formatting = { month: 'numeric', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}

export const formatDateToDMYT = (value, formatting = { month: 'numeric', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
export function datetolocale(value) { return new Date(value).toLocaleDateString() }

/**
 * DxGrid için decimal value'ları Türkçe formatlama yapar
 * @param cellValue DxGrid cell
 */
export function customizeDecimalDxGrid(cellValue) {
  let value = cellValue.value + 1 ? cellValue.value : cellValue; // +1 value kendisi 0 olursa false yapar
  if (value.toString().includes(',')) value = value.replace(',', '.')
  const formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  });
  return formattedOutput.format(value).replace('₺', '')
}

/**
 * DxGrid için JS Date object yapar
 * @param docDate Uyum'dan gelen teklif docDate
 * @returns { Date } Date objesi
 */

export function uyumTeklifDocDatetoJSDate(docDate) {
  const date = docDate.split(' ')[0].split('.');
  const year = date[2];
  const month = date[1] - 1; // JS Date için month 0 based 
  const day = date[0];
  return new Date(year, month, day)
}

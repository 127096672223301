import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  referansList: [],
  referansFilteredList: [],
}

const getters = {
  getReferanses(state) {
    return state.referansFilteredList
  },
  getReferansFilter(state) {
    return state.referansFilter
  },
}

const mutations = {
  setReferanses(state, referansList) {
    state.referansList = referansList
    state.referansFilteredList = referansList
  },
  setReferansFilter(state, { key, value }) {
    state.referansFilter[key] = value
    state.referansFilteredList = state.referansList
  },
}

const actions = {
  fetchReferanses(context, queryParams) {
    return ApiService.get('manage/referans/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setReferanses', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchReferans(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/referans/get/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveReferans(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('manage/referans/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeReferans(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/referans/remove/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActiveReferans(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/referans/activetoggle/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

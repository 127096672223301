import { sortCompare } from '../../api.utils'

const UrunYonetimService = {
  
  filterUrunList(list, queryParams) {
    const {
      urunGrupId = null,
      isActive = null,
      dBIsActive = null,
      isShowInWebSite = null,
      query = '',
    } = queryParams
    
    const queryLowered = query.toLowerCase()
    const filteredData = list.filter(
      urun => {
        let result = (urun.sUrunKodu.toLowerCase().includes(queryLowered)
                    || urun.sUrunAdi.toLowerCase().includes(queryLowered))
        
        if (urun.sUrunBaslik) {
          result = urun.sUrunBaslik.toLowerCase().includes(queryLowered)
        }
        
        if (urunGrupId) {
          result = result && (urun.urunGrup_Id === urunGrupId)
        }

        if (isActive !== null) {
          result = result && (urun.isActive === isActive)
        }
        
        if (dBIsActive !== null) {
          result = result && (urun.dbIsActive === dBIsActive)
        }

        if (isShowInWebSite !== null) {
          result = result && (urun.isShowInWebSite === isShowInWebSite)
        }

        return result;
      },
    )
    
    return filteredData
  },
}

export default UrunYonetimService

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  ticketList: [],
  myTicketList: [],
  ticketFilteredList: [],
  closedTicketList: [],
  myTicketFilteredList: [],
  ticketFilter: {
    departmentTuru: null,
    ticketOncelikTuru: null,
    musteri_Id: null,
    query: '',
  },

  myTicketFilter: {
    departmentTuru: null,
    ticketOncelikTuru: null,
    musteri_Id: null,
    query: '',
  },
}

const helpers = {
  filterTicketList(list, filter) {
    const queryLowered = filter.query.toLowerCase();
    return list.filter(ticket => (ticket.ticket.sKonu.toLowerCase().includes(queryLowered)
      && ticket.ticket.departmentTuru === (filter.departmentTuru || ticket.ticket.departmentTuru))
      && ticket.ticket.ticketOncelikTuru === (filter.ticketOncelikTuru || ticket.ticket.ticketOncelikTuru)
      && ticket.ticket.musteri_Id === (filter.musteri_Id || ticket.ticket.musteri_Id))
  },
}

const getters = {
  getTickets(state) {
    return state.ticketFilteredList
  },

  getClosedTickets(state) {
    return state.closedTicketList
  },

  getMyTickets(state) {
    return state.myTicketFilteredList
  },

  getTicketFilter(state) {
    return state.ticketFilter
  },
  
  getMyTicketFilter(state) {
    return state.myTicketFilter
  },
}

const mutations = {
  setTickets(state, ticketList) {
    state.ticketList = ticketList
    state.ticketFilteredList = helpers.filterTicketList(ticketList, state.ticketFilter);
  },

  setClosedTickets(state, ticketList) {
    state.closedTicketList = ticketList
  },

  setTicketFilter(state, { key, value }) {
    state.ticketFilter[key] = value
    state.ticketFilteredList = helpers.filterTicketList(state.ticketList, state.ticketFilter)
  },
  
  setMyTickets(state, ticketList) {
    state.myTicketList = ticketList
    state.myTicketFilteredList = helpers.filterTicketList(ticketList, state.myTicketFilter);
  },
  
  setMyTicketFilter(state, { key, value }) {
    state.myTicketFilter[key] = value
    state.myTicketFilteredList = helpers.filterTicketList(state.myTicketList, state.myTicketFilter)
  },
}

const actions = {
  fetchTickets(context, queryParams) {
      return ApiService.post('manage/destek/ticket/getall', queryParams, this)
        .then(response => {
          if (response.statusCode === 200) {
            if (queryParams.self === 0) context.commit('setTickets', response.result)
            if (queryParams.self === 1) context.commit('setMyTickets', response.result)
            if (!queryParams.isActive) context.commit('setClosedTickets', response.result)
          } else {
            AlertService.error(this._vm, response.message)
          }
        })
  },

  fetchTicket(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/destek/ticket/get/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchTicketDetaylar(context, ticketId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/destek/ticket/detaylar/${ticketId}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchAssignedTickets(context, ticketId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/destek/ticket/assigned/${ticketId}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  sendAnswer(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('manage/destek/ticket/answer/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  closeTicket(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/destek/ticket/close/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  assignTicket(context, queryParams) {
    return new Promise((resolve, reject) => {
    ApiService.post('manage/destek/ticket/assign/', queryParams, this)
      .then(response => resolve(response))
      .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import DuyuruYonetimService from '@/common/custom-service/management/DuyuruYonetimService'
import AlertService from '@/common/alert.service'
import { getCurrentDate } from '@core/utils/filter';

const state = {
  duyuruList: [],
  duyuruFilteredList: [],
  duyuruLocalFilter: {
    dtBaslangic: getCurrentDate({ type: 'm', value: -1 }),
    dtBitis: getCurrentDate(),
    uygulamaTipi: null,
    sortBy: 'id',
    sortDesc: false,
    query: '',
  },
}

const getters = {
  getDuyurus(state) {
    return state.duyuruFilteredList
  },
  getDuyuruLocalFilters(state) {
    return state.duyuruLocalFilter
  },
}

const mutations = {
  setDuyurus(state, duyuruList) {
    state.duyuruList = DuyuruYonetimService.getUygulamaTuru(duyuruList)
    state.duyuruFilteredList = DuyuruYonetimService.filterDuyuruList(duyuruList, state.duyuruLocalFilter)
  },
  setDuyuruFilter(state, { key, value }) {
    state.duyuruLocalFilter[key] = value
    state.duyuruFilteredList = DuyuruYonetimService.filterDuyuruList(state.duyuruList, state.duyuruLocalFilter)
  },

  refreshDuyuruFilter(state, filterParams) {
    Object.keys(state.duyuruLocalFilter).forEach(key => {
      if (filterParams[key]) {
        state.duyuruLocalFilter[key] = filterParams[key]
      } else if (key === 'sortBy') {
          state.duyuruLocalFilter[key] = 'id'
        } else if (key === 'query') {
          state.duyuruLocalFilter[key] = ''
        } else {
          state.duyuruLocalFilter[key] = null
        }
    });
  },
}

const actions = {
  fetchDuyurus(context, queryParams) {
    queryParams.dtBaslangic = state.duyuruLocalFilter.dtBaslangic;
    queryParams.dtBitis = state.duyuruLocalFilter.dtBitis;
    return ApiService.post('manage/uygulamaduyuruyonetim/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setDuyurus', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchDuyuru(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/uygulamaduyuruyonetim/get/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveDuyuru(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/uygulamaduyuruyonetim/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeDuyuru(context, sGuid) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/uygulamaduyuruyonetim/delete/${sGuid}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

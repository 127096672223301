import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import UrunYonetimService from '@/common/custom-service/management/UrunYonetimService'

const state = {
  urunSelectionList: [],
  urunList: [],
  urunFilteredList: [],
  urunLocalFilter: {
    urunGrupId: null,
    isActive: null,
    dBIsActive: null,
    isShowInWebSite: null,
    query: '',
  },
  appVersions: { channel: { items: [] } },
  versions: [],
}

const getters = {
  getSelectionUruns(state) {
    return state.urunSelectionList;
  },
  getUruns(state) {
    return state.urunFilteredList
  },
  getUrunLocalFilters(state) {
    return state.urunLocalFilter
  },
  iosVersions({ versions }) {
    return versions.filter(x => x.os === 1);
  },
  androidVersions({ versions }) {
    return versions.filter(x => x.os === 0);
  },
}

const mutations = {
  setSelectionUruns(state, urunList) {
    state.urunSelectionList = urunList
  },
  setUruns(state, urunList) {
    state.urunList = urunList
    state.urunFilteredList = UrunYonetimService.filterUrunList(urunList, state.urunLocalFilter)
  },
  setUrunFilter(state, { key, value }) {
    state.urunLocalFilter[key] = value
    state.urunFilteredList = UrunYonetimService.filterUrunList(state.urunList, state.urunLocalFilter)
  },
  setAppVersions(state, appVersions) {
    state.versions = appVersions;
  },
  addAppVersion({ versions }, { v, o }) {
    const added = true;
    const _id = `${o}_${v}_${added}`;
    versions.unshift({ version: v, os: o, added: true, id: _id });
  },
  removeAppVersion({ versions }, { os, version, added }) {
    const id = `${os}_${version}_${added}`;
    const g = versions.find(x => x.id === id);
    const i = versions.indexOf(g);
    versions.splice(i, 1);
  },
}

const actions = {
  fetchSelectionUruns(context) {
    return ApiService.get('manage/kullanicihesap/musteri/selection', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSelectionUruns', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchUruns(context, queryParams) {
    return ApiService.post('manage/urun/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setUruns', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  refreshUrunsFromApi(context) {
    return new Promise((resolve, reject) => {
      ApiService.get('manage/urun/download/api', {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchUrun(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/urun/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveUrunGenel(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/urun/genel/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchUrunAciklama(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/urun/aciklama/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveUrunAciklama(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/urun/aciklama/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActiveUrun(context, { id, type }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/urun/activetoggle/${type}/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchAppVersions(context) {
    return new Promise((resolve, reject) => {
      ApiService.get('manage/urun/mobile/versioncontrol', {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            context.commit('setAppVersions', response.result)
          } 
        })
        .catch(error => reject(error))
    })
  },
  saveAppVersion(context, { os, version, id }) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/urun/mobile/versioncontrol/${os}/${version}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            AlertService.success(this._vm, response.message);
            context.dispatch('fetchAppVersions');
          } else {
            AlertService.error(this._vm, response.message);
            context.commit('removeAppVersion', { os, version, added: true });
          }
        })
        .catch(error => reject(error))
    })
  },

  deleteAppVersion(context, { os, version }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`manage/urun/mobile/versioncontrol/${os}/${version}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            AlertService.success(this._vm, response.message);
            context.dispatch('fetchAppVersions');
          } else {
            AlertService.error(this._vm, response.message);
          }
        })
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  sayfaList: [],
  sayfaFilteredList: [],
  sayfaFilter: {
    webSiteSayfaTur: null,
    query: '',
  },
}

const helpers = {
  filterSayfaList(list) {
    const queryLowered = state.sayfaFilter.query.toLowerCase();
    return list.filter(sayfa => (sayfa.sUrl.toLowerCase().includes(queryLowered)
      || (sayfa.sBaslik).toLowerCase().includes(queryLowered))
      && sayfa.webSiteSayfaTur === (state.sayfaFilter.webSiteSayfaTur || sayfa.webSiteSayfaTur))
  },
}

const getters = {
  getSayfas(state) {
    return state.sayfaFilteredList
  },
  getSayfaFilter(state) {
    return state.sayfaFilter
  },
}

const mutations = {
  setSayfas(state, sayfaList) {
    state.sayfaList = sayfaList
    state.sayfaFilteredList = helpers.filterSayfaList(sayfaList)
  },

  setSayfaFilter(state, { key, value }) {
    state.sayfaFilter[key] = value
    state.sayfaFilteredList = helpers.filterSayfaList(state.sayfaList)
  },
}

const actions = {
  fetchSayfas(context, queryParams) {
    return ApiService.post('manage/sayfa/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSayfas', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchSayfa(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/sayfa/get/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveSayfa(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('manage/sayfa/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeSayfa(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/sayfa/remove/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActiveSayfa(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/sayfa/activetoggle/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  bankaBilgiList: [],
}

const getters = {
  getBankaBilgis(state) {
    return state.bankaBilgiList
  },
}

const mutations = {
  setBankaBilgis(state, bankaBilgiList) {
    state.bankaBilgiList = bankaBilgiList
  },
}

const actions = {
  fetchBankaBilgis(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get('manage/bankabilgi/getall', queryParams, this)
        .then(response => {
          if (response.statusCode === 200) {
          context.commit('setBankaBilgis', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
      .catch(error => reject(error))
    })
  },

  fetchBankaBilgi(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get('manage/bankabilgi/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveBankaBilgi(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/bankabilgi/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  dbFirmaInfoList: [],
  localFilter: {
    isActive: null,
    sDBUserName: null,
    sortBy: 'id',
    sortDesc: false,
    query: '',
  },
}

const getters = {
  getDbFirmaInfos(state) {
    return state.dbFirmaInfoList
  },
  getdbFirmaInfoLocalFilters(state) {
    return state.localFilter
  },
}

const mutations = {
  setDbFirmaInfos(state, dbFirmaInfoList) {
    state.dbFirmaInfoList = dbFirmaInfoList.map(x => { 
      x.passwordHidden = true 
      return x;
    });
  },
}

const actions = {
  fetchDbFirmaInfos(context) {
    return ApiService.get('manage/dbinfo/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setDbFirmaInfos', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  saveDbFirmaInfos(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/dbinfo/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActivedbFirmaInfo(context, sGuid) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/dbinfo/activetoggle/${sGuid}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleIrsaliyeService(context, sGuid) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/dbinfo/irsaliyeservistoggle/${sGuid}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { getCurrentDate, formatDateToDMYT } from '@core/utils/filter';

const state = {
  contactFormList: [],
  contactReadFormList: [],
  contactFormFilter: {
    dtBaslangic: getCurrentDate({ type: 'd', value: -1 }),
    dtBitis: getCurrentDate({ type: 'd', value: 1 }),
  },
  contactReadFormFilter: {
    dtBaslangic: getCurrentDate({ type: 'd', value: -1 }),
    dtBitis: getCurrentDate({ type: 'd', value: 1 }),
  },
  columns: [
    { key: 'dtCreatedFormatted', label: 'Tarih', sortable: true },
    { key: 'sAdSoyad', label: 'Ad' },
    { key: 'sEPosta', label: 'E-Posta' },
    { key: 'sTelefon', label: 'Telefon', sortable: true },
    { key: 'sKonu', label: 'Konu' },
    { key: 'sMesaj', label: 'Mesaj' },
  ],
}

const helpers = {
  dateFormat(item) {
    item.dtCreatedFormatted = formatDateToDMYT(item.dtCreated);
    return item;
  },
}

const getters = {
  getContactForms: () => state.contactFormList,
  getContactReadForms: () => state.contactReadFormList,
  getContactFormFilter: () => state.contactFormFilter,
  getContactReadFormFilter: () => state.contactReadFormFilter,
  getColumns: () => state.columns,
}

const mutations = {
  setContactForms(state, contactFormList) {
    state.contactFormList = contactFormList.map(helpers.dateFormat).filter(x => !x.isRead)
    state.contactReadFormList = contactFormList.map(helpers.dateFormat).filter(x => x.isRead)
  },
  setContactFormFilter(state, { key, value }) {
    state.contactFormFilter[key] = value
  },
  setContactReadFormFilter(state, { key, value }) {
    state.contactReadFormFilter[key] = value
  },
}

const actions = {
  fetchContactForms(context) {
    return ApiService.post('manage/contactform/getall', state.contactFormFilter, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setContactForms', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  setRead(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/contactform/setread/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

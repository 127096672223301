import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  siparisList: [],
  siparisData: null,
}

const helpers = {
  mapSiparisList(responseData) {
    return responseData.result.siparisList.map(item => {
      item.siparisDetayList.map(detay => {
        detay.stockName = detay.manuelStokAdi ?? detay.stokAdi;
        if (detay.manuelStokAdi === '') detay.stockName = detay.stokAdi;
        return detay;
      });
      const findSatisPersonel = responseData.result.satisPersonelInfoList.find(satisPersonelItem => satisPersonelItem.sKodu === item.satisTemsilciKodu);
      if (findSatisPersonel) {
        item.salesPersonName = findSatisPersonel.sAdSoyad;
      }
      return item;
    });
  },
}

const getters = {
  getSiparises(state) {
    return state.siparisList;
  },

  getSiparisData(state) {
    return state.siparisData;
  },
}

const mutations = {
  setSiparises(state, siparisList) {
    state.siparisList = siparisList
  },

  setSiparisData(state, siparisData) {
    state.siparisData = siparisData
  },
}

const actions = {
  fetchSiparises(context) {
    return ApiService.get('customer/siparis/getall/erpapi', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          // Siparis Satis Personel Kontrol yapilcak
          context.commit('setSiparises', helpers.mapSiparisList(response))
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchSiparisSingle(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('customer/siparis/get/erpapi', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSiparisOdemeFrame(context) {
    return new Promise((resolve, reject) => {
      ApiService.get('payment/customer/siparis', {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  setSiparisOdemeIstek(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('customer/siparis/odeme/istek/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchOdemeDekontFile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.download(`customer/siparis/odeme/istek/download/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import DBKullaniciYonetimService from '@/common/custom-service/management/DBKullaniciYonetimService';

const state = {
  db: {
    kullaniciList: [],
    filteredList: [],
    localFilter: {
      dbFirmaInfo_Id: null,
      isActive: null,
      sKullaniciAdi: null,
      musteriId: null,
      sortBy: 'id',
      sortDesc: false,
      query: '',
    },
  },
  webApi: {
    kullaniciList: [],
    filteredList: [],
    localFilter: {
      dbFirmaInfo_Id: null,
      isActive: null,
      sKullaniciAdi: null,
      musteriId: null,
      sortBy: 'id',
      sortDesc: false,
      query: '',
    },
  },
}

const getters = {
  getDBKullanicis(state) {
    return state.db.filteredList
  },
  getDBKullaniciLocalFilters(state) {
    return state.db.localFilter
  },
  getWebApiKullanicis(state) {
    return state.webApi.filteredList
  },
  
  getWebApiKullaniciLocalFilters(state) {
    return state.webApi.localFilter
  },
}

const mutations = {
  setDBKullanicis(state, dbkullaniciList) {
    state.db.kullaniciList = dbkullaniciList.map(x => { 
      x.passwordHidden = true 
      return x;
    });
    state.db.filteredList = DBKullaniciYonetimService.filterDBKullaniciList(state.db.kullaniciList, state.db.localFilter);
  },
  setDBKullaniciFilter(state, { key, value }) {
    state.db.localFilter[key] = value;
    state.db.filteredList = DBKullaniciYonetimService.filterDBKullaniciList(state.db.kullaniciList, state.db.localFilter);
  },

  setWebApiKullanicis(state, dbkullaniciList) {
    state.webApi.kullaniciList = dbkullaniciList.map(x => { 
      x.passwordHidden = true 
      return x;
    });
    state.webApi.filteredList = DBKullaniciYonetimService.filterDBKullaniciList(state.webApi.kullaniciList, state.webApi.localFilter);
  },
  
  setWebApiKullaniciFilter(state, { key, value }) {
    state.webApi.localFilter[key] = value
    state.webApi.filteredList = DBKullaniciYonetimService.filterDBKullaniciList(state.webApi.kullaniciList, state.webApi.localFilter);
  },
 
  refreshDBKullaniciFilter(state, filterParams) {
    Object.keys(state.dbkullaniciLocalFilter).forEach(key => {
      if (filterParams[key]) {
        state.dbkullaniciLocalFilter[key] = filterParams[key]
      } else if (key === 'sortBy') {
          state.dbkullaniciLocalFilter[key] = 'id'
        } else if (key === 'query') {
          state.dbkullaniciLocalFilter[key] = ''
        } else {
          state.dbkullaniciLocalFilter[key] = null
        }
    });
  },
}

const actions = {
  fetchWebApiKullanicis(context, queryParams) {
    queryParams.kullaniciTuru_Id = 1;
    return ApiService.post('manage/dbkullanici/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setWebApiKullanicis', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchDBKullanicis(context, queryParams) {
    queryParams.kullaniciTuru_Id = 2;
    return ApiService.post('manage/dbkullanici/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setDBKullanicis', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchDBKullanici(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/dbkullanici/get/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveDBKullanici(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/dbkullanici/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActiveDBKullanici(context, sGuid) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/dbkullanici/activetoggle/${sGuid}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  engelKaldir(context, sKullaniciAdi) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/dbkullanici/engelkaldir/${sKullaniciAdi}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  delete(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/dbkullanici/delete/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

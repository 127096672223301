import ApiService from '@/common/api.service'
import { getCurrentDate } from '@core/utils/filter';

const state = {
  bayiTalebLocalFilter: {
    pageIndex: 1,
    dtBitis: getCurrentDate(),
    pageSize: 10,
    search: '',
    webSiteHizmetSonucDurum: null,
  },
}

const getters = {
  getBayiTalebFilters(state) {
    return state.bayiTalebLocalFilter;
  },
}

const mutations = {
}

const actions = {
  fetchBayiTalebs(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/hizmettaleb/bayilist', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { getCurrentDate } from '@core/utils/filter';

const state = {
  paymentList: [],
  paymentFilter: {
    dtBaslangic: getCurrentDate({ type: 'd', value: -1 }),
    dtBitis: getCurrentDate(),
    musteriId: null,
    personelId: null,
  },
}

const getters = {
  getPayments: () => state.paymentList,
  getPaymentFilter: () => state.paymentFilter,
}

const mutations = {
  setPayments(state, paymentList) {
    state.paymentList = paymentList
  },
  
  setPaymentFilter(state, { key, value }) {
    state.paymentFilter[key] = value
  },
}

const actions = {
  fetchPayments(context) {
    return ApiService.post('manage/odeme/getall', state.paymentFilter, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setPayments', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  ayar: {},
}

const getters = {
  getGenelAyars() {
    return state.ayar;
  },
}

const mutations = {
  setGenelAyars(state, ayar) {
    state.ayar = ayar;
  },
}

const actions = {
  fetchGenelAyars(context) {
    ApiService.get('manage/genelayar/get', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setGenelAyars', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  saveErpAyar(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/genelayar/erp/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveOdemeAyar(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/genelayar/odeme/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  teklifAciklamaList: [],
  teklifAciklamaFilteredList: [],
  teklifFilter: {
    query: '',
    teklifAciklamaTuru: null,
  },
}

const helpers = {
  filterTeklifAciklamaList(list) {
    const queryLowered = state.teklifFilter.query.toLowerCase();
    return list.filter(teklifAciklama => {
      teklifAciklama.sAciklama = teklifAciklama.sAciklama ? teklifAciklama.sAciklama : '';
      return (teklifAciklama.sAciklama.toLowerCase().includes(queryLowered)
      && teklifAciklama.teklifAciklamaTuru === (state.teklifFilter.teklifAciklamaTuru || teklifAciklama.teklifAciklamaTuru))
    })
  },
}

const getters = {
  getTeklifAciklamas(state) {
    return state.teklifAciklamaFilteredList;
  },
  getTeklifAciklamaFilter(state) {
    return state.teklifFilter;
  },
}

const mutations = {
  setTeklifAciklamas(state, teklifAciklamaList) {
    state.teklifAciklamaList = teklifAciklamaList;
    state.teklifAciklamaFilteredList = helpers.filterTeklifAciklamaList(state.teklifAciklamaList);
  },
  setTeklifAciklamaFilter(state, { key, value }) {
    state.teklifFilter[key] = value;
    state.teklifAciklamaFilteredList = helpers.filterTeklifAciklamaList(state.teklifAciklamaList);
  },
}

const actions = {
  fetchTeklifAciklamas(context) {
    return ApiService.get('manage/teklifaciklama/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setTeklifAciklamas', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchTeklifAciklama(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/teklifaciklama/get/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveTeklifAciklama(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklifaciklama/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActiveTeklifAciklama(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/teklifaciklama/toggle/isactive/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  // Urun
  fetchUrunTeklifAciklamas(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklifaciklama/urun/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  urunTeklifAciklamaBagla(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklifaciklama/urun/bagla', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  urunTeklifAciklamaBagKopar(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklifaciklama/urun/baglakopar', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { getCurrentDate, getFirstDayOfYear } from '@core/utils/filter';

const state = {
  siparisList: [],
  arsivSiparisList: [],
  siparisFilter: {
    dtBaslangic: getFirstDayOfYear(),
    dtBitis: getCurrentDate(),
    entityId: null,
    siparisDurum: 0,
  },
  siparisArsivFilter: {
    dtBaslangic: getFirstDayOfYear(),
    dtBitis: getCurrentDate(),
    entityId: null,
    siparisDurum: null,
  },
}

const helpers = {
  mapSiparisList(responseData) {
    return responseData.result.siparisList.map(item => {
      item.siparisDetayList.map(detay => {
        detay.stockName = detay.manuelStokAdi ?? detay.stokAdi;
        if (detay.manuelStokAdi === '') detay.stockName = detay.stokAdi;
        return detay;
      });
      const findSatisPersonel = responseData.result.satisPersonelInfoList.find(satisPersonelItem => satisPersonelItem.sKodu === item.satisTemsilciKodu);
      if (findSatisPersonel) {
        item.salesPersonName = findSatisPersonel.sAdSoyad;
      }
      return item;
    });
  },
}

const getters = {
  getSiparises(state) {
    return state.siparisList
  },
  getArsivSiparises(state) {
    return state.arsivSiparisList
  },
  getSiparisFilters(state) {
    return state.siparisFilter
  },
  getArsivSiparisFilters(state) {
    return state.siparisArsivFilter
  },
}

const mutations = {
  setSiparises(state, siparisList) {
    state.siparisList = siparisList
  },
  setArsivSiparises(state, siparisList) {
    state.arsivSiparisList = siparisList
  },
  setSiparisFilter(state, { key, value }) {
    state.siparisFilter[key] = value
  },
  setArsivSiparisFilter(state, { key, value }) {
    state.siparisArsivFilter[key] = value
  },
}

const actions = {
  fetchSiparises({ commit, state }, queryParams) {
    if (!queryParams) {
      queryParams = state.siparisFilter
    }
    return ApiService.post('manage/siparis/getall/erpapi', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          // Siparis Satis Personel Kontrol yapilcak
          commit('setSiparises', helpers.mapSiparisList(response))
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchArsivSiparises({ commit, state }, queryParams) {
    if (!queryParams) {
      queryParams = state.siparisArsivFilter
    }
    return ApiService.post('manage/siparis/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          // Siparis Satis Personel Kontrol yapilcak
          commit('setArsivSiparises', helpers.mapSiparisList(response))
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchSiparisSingle(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/get/erpapi', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSiparisArsivSingle(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  siparisOnayla(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/onayla', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  siparisOdeme(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/odeme', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  siparisGerekliKurulum(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/gereklikurulum', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  siparisKapat(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/sipariskapat', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  siparisSil(context, siparisId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/siparis/siparissil/${siparisId}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  siparisYenile(context, siparisId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/siparis/siparisyenile/${siparisId}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  siparisYenileOnayla(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/yenile/onayla', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSiparisDurumGecmisis(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/siparis/getall/siparisdurumgecmis', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchOdemeDekontFile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.download(`manage/siparis/odeme/istek/download/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default {
    state: {
        loading: false,
    },
    getters: {
        getLoader: state => state.loading,
    },
    mutations: {
        setLoader(state, value) {
            state.loading = value
        },
    },
}

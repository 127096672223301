import { Role } from '@/auth/utils'

export default [
  {
    path: '/management/home',
    name: 'manage-home',
    component: () => import('@/views/management/Home.vue'),
    meta: {
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  // Kullanıcı Yönetim
  {
    path: '/management/kullanici',
    name: 'manage-kullanici',
    component: () => import('@/views/management/kullanici-yonetim/KullaniciList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kullanıcı',
        },
      ],
      authorize: [Role.SuperAdmin],
    },
  },
  {
    path: '/management/kullanici/:id/:musteri_id',
    name: 'manage-kullanici-save',
    component: () => import('@/views/management/kullanici-yonetim/KullaniciSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kullanıcı',
          to: '/management/kullanici',
        },
        {
          text: 'Kullanıcı Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin],
    },
  },

  // Müşteri Yönetim
  {
    path: '/management/musteri',
    name: 'manage-musteri',
    component: () => import('@/views/management/musteri-yonetim/MusteriList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Müşteri',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/musteri/:id',
    name: 'manage-musteri-save',
    component: () => import('@/views/management/musteri-yonetim/MusteriSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Müşteri',
          to: '/management/musteri/',
        },
        {
          text: 'Müşteri Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Ürün Yönetim
  {
    path: '/management/urun',
    name: 'manage-urun',
    component: () => import('@/views/management/urun-yonetim/UrunList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ürün',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/urun/detay/:id',
    name: 'manage-urun-detay',
    component: () => import('@/views/management/urun-yonetim/urun-detay/UrunDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ürün',
          to: '/management/urun/',
        },
        {
          text: 'Ürün Düzenleme',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Teklif Yönetim
  {
    path: '/management/teklif',
    name: 'manage-teklif',
    component: () => import('@/views/management/teklif-yonetim/TeklifList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Teklifler',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/teklif/kapanan',
    name: 'manage-teklif-kapanan',
    component: () => import('@/views/management/teklif-yonetim/KapananTeklifList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kapanan Teklifler',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  
  // Teklif Detail
  {
    path: '/management/teklif/islem/:id',
    name: 'management-teklif-islem',
    component: () => import('@/views/management/teklif-yonetim/teklif-islem/TeklifIslem.vue'),
    meta: {
      authorize: [Role.SuperAdmin, Role.SuperOperator],
      breadcrumb: [
        {
          text: 'Teklifler',
          to: '/management/teklif',
        },
        {
          text: 'Teklif Detay İşlemleri',
        },
      ],
    },
  },

  // Siparis Yönetim
  {
    path: '/management/siparis',
    name: 'manage-siparis',
    component: () => import('@/views/management/siparis-yonetim/SiparisList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Sipariş',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/siparis/detay/:id',
    name: 'manage-siparis-detay',
    component: () => import('@/views/management/siparis-yonetim/siparis-detay/SiparisDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Sipariş',
          to: '/management/siparis/',
        },
        {
          text: 'Sipariş Detay',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/siparis/arsiv',
    name: 'manage-siparis-arsiv',
    component: () => import('@/views/management/siparis-yonetim/arsiv/SiparisArsivList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Sipariş Arşiv',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/siparis/arsiv/detay/:id',
    name: 'manage-siparis-arsiv-detay',
    component: () => import('@/views/management/siparis-yonetim/arsiv/SiparisArsivDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Sipariş Arşiv',
          to: '/management/siparis/arsiv',
        },
        {
          text: 'Sipariş Detay',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/siparis/yenile/detay/:id',
    name: 'manage-siparis-yenileme-detay',
    component: () => import('@/views/management/siparis-yonetim/siparis-yenileme/SiparisYenilemeDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Sipariş Detay',
          to: '/management/siparis/',
        },
        {
          text: 'Sipariş Detay',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

   // Lisans Yönetim
  {
    path: '/management/lisanssifrebilgi',
    name: 'manage-lisanssifrebilgi',
    component: () => import('@/views/management/lisanssifrebilgi-yonetim/LisansSifreBilgiList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Lisans Yönetim',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Cari Ekstra Yönetim
  {
    path: '/management/cariekstra',
    name: 'manage-cariekstra',
    component: () => import('@/views/management/cariekstra-yonetim/CariEkstraList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Cari Ekstre',
        },
      ],

      authorize: [Role.SuperAdmin],
    },
  },

  // Odeme Yönetim
  {
    path: '/management/odemeler',
    name: 'manage-payments',
    component: () => import('@/views/management/odeme-yonetim/OdemeList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ödemeler',
        },
      ],

      authorize: [Role.SuperAdmin],
    },
  },

  // Tanım Yönetim
  {
    path: '/management/vergidaire/',
    name: 'manage-vergidaire',
    component: () => import('@/views/management/tanim-yonetim/vergidaire/VergiDaireList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Vergi Daire',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/vergidaire/create',
    name: 'manage-vergidaire-create',
    component: () => import('@/views/management/tanim-yonetim/vergidaire/VergiDaireSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Vergi Daire',
          to: '/management/vergidaire',
        },
        {
          text: 'Vergi Daire Ekleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/adres/',
    name: 'manage-adres',
    component: () => import('@/views/management/tanim-yonetim/adres/AdresList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Adres',
        },
      ],

      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/adres/create',
    name: 'manage-adres-create',
    component: () => import('@/views/management/tanim-yonetim/adres/AdresSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Adres',
          to: '/management/adres',
        },
        {
          text: 'Adres Ekleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/bankabilgi/',
    name: 'manage-bankabilgi',
    component: () => import('@/views/management/tanim-yonetim/bankabilgi/BankaBilgiList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Banka Bilgi',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/urungrup/',
    name: 'manage-urungrup',
    component: () => import('@/views/management/tanim-yonetim/urungrup/UrunGrupList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ürün Grup',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/urungrup/save/:id',
    name: 'manage-urungrup-save',
    component: () => import('@/views/management/tanim-yonetim/urungrup/UrunGrupSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Ürün Grup',
          to: '/management/urungrup',
        },
        {
          text: 'Ürün Grup Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/genelayar',
    name: 'manage-genelayar',
    component: () => import('@/views/management/tanim-yonetim/genelayarlar/GenelAyarlar.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Genel Ayar',
        },
      ],
      authorize: [Role.SuperAdmin],
    },
  },
  {
    path: '/management/sozlesme',
    name: 'manage-sozlesme',
    component: () => import('@/views/management/tanim-yonetim/sozlesme/Sozlesme.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Sözleşme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/teklifaciklama',
    name: 'manage-teklifaciklama',
    component: () => import('@/views/management/tanim-yonetim/teklifaciklama/TeklifAciklamaList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Teklif Açıklamaları',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/satispersonel/',
    name: 'manage-satispersonel',
    component: () => import('@/views/management/tanim-yonetim/satis-personel/SatisPersonelList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Satış Personel',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/satispersonel/save/:id',
    name: 'manage-satispersonel-save',
    component: () => import('@/views/management/tanim-yonetim/satis-personel/SatisPersonelSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Satış Personel',
          to: '/management/satispersonel',
        },
        {
          text: 'Satış Personel Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Demo
  {
    path: '/management/demo-taleb',
    name: 'demo-taleb',
    component: () => import('@/views/management/hizmet-taleb/demo/DemoTalebList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Demo Yönetim',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Demo
  {
    path: '/management/bayi-taleb',
    name: 'bayi-taleb',
    component: () => import('@/views/management/hizmet-taleb/bayi/BayiTalebList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Bayı Yönetim',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  
  // Duyuru Yönetim
  {
    path: '/management/duyuru',
    name: 'manage-duyuru',
    component: () => import('@/views/management/duyuru-yonetim/DuyuruList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Duyuru Yönetim',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/duyuru/:id',
    name: 'manage-duyuru-save',
    component: () => import('@/views/management/duyuru-yonetim/DuyuruSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Duyuru Yönetim',
          to: '/management/duyuru',
        },
        {
          text: 'Duyuru Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // WebApi Kullanici Yönetim
  {
    path: '/management/webapi-kullanici',
    name: 'manage-webapi-kullanici',
    component: () => import('@/views/management/cloudkullanici-yonetim/web-api/WebApiKullaniciList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Cloud Kullanıcılar',
        },
        {
          text: 'WebApi Kullanıcıları',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  // DBFirmaInfo Yönetim
  {
    path: '/management/dbfirmainfo',
    name: 'manage-dbfirmainfo',
    component: () => import('@/views/management/database-yonetim/dbfirmainfo-yonetim/DBFirmaInfoList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Firma Veritabanları',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // AppAuthForm Loglar
  {
    path: '/management/logs',
    name: 'manage-logs',
    component: () => import('@/views/management/lisanssifrebilgi-yonetim/logs/LogList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Loglar',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Veritabani Kullanici
  {
    path: '/management/db-kullanici',
    name: 'manage-db-kullanici',
    component: () => import('@/views/management/cloudkullanici-yonetim/db/DBKullaniciList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Cloud Kullanıcılar',
        },
        {
          text: 'Veri Tabanı Kullanıcıları',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Referans Yönetim
  {
    path: '/management/referans',
    name: 'manage-website-referans',
    component: () => import('@/views/management/website-yonetim/referans/ReferansList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Referans',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/referans/save/:id',
    name: 'manage-website-referans-save',
    component: () => import('@/views/management/website-yonetim/referans/ReferansDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Referans',
          to: '/management/referans',
        },
        {
          text: 'Referans Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // CozumOrtak Yönetim
  {
    path: '/management/cozumortak',
    name: 'manage-website-cozumortak',
    component: () => import('@/views/management/website-yonetim/cozum-ortak/CozumOrtakList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Çözüm Ortak',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/cozumortak/save/:id',
    name: 'manage-website-cozumortak-save',
    component: () => import('@/views/management/website-yonetim/cozum-ortak/CozumOrtakDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Çözüm Ortak',
          to: '/management/cozumortak',
        },
        {
          text: 'Çözüm Ortak Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Banner Yönetim
  {
    path: '/management/banner',
    name: 'manage-website-banner',
    component: () => import('@/views/management/website-yonetim/banner/BannerList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Banner',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/banner/save/:id',
    name: 'manage-website-banner-save',
    component: () => import('@/views/management/website-yonetim/banner/BannerDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Banner',
          to: '/management/banner',
        },
        {
          text: 'Banner Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Sayfa Yönetim
  {
    path: '/management/sayfa',
    name: 'manage-website-sayfa',
    component: () => import('@/views/management/website-yonetim/sayfa/SayfaList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Sayfa',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },
  {
    path: '/management/sayfa/save/:id',
    name: 'manage-website-sayfa-save',
    component: () => import('@/views/management/website-yonetim/sayfa/SayfaDetay.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Sayfa',
          to: '/management/sayfa',
        },
        {
          text: 'Sayfa Düzenleme',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Website Genel
  {
    path: '/management/website-genel',
    name: 'manage-website-genel',
    component: () => import('@/views/management/website-yonetim/genel/WebsiteGenelSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Genel',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Website Hakkımızda
  {
    path: '/management/website-aboutus',
    name: 'manage-website-aboutus',
    component: () => import('@/views/management/website-yonetim/about-us/WebsiteAboutUsSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'Hakkımızda',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // İletişim
  {
    path: '/management/website-address',
    name: 'manage-website-address',
    component: () => import('@/views/management/website-yonetim/address/WebsiteAddressSave.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'İletişim',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // İletişim Form
  {
    path: '/management/website-contact-form',
    name: 'manage-website-contact-form',
    component: () => import('@/views/management/website-yonetim/contact-form/ContactFormList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Website',
        },
        {
          text: 'İletişim Formları',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Uygulama Versiyon 
  {
    path: '/management/app-version',
    name: 'manage-app-version',
    component: () => import('@/views/management/apps/AppVersion.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Uygulama Yönetim',
        },
        {
          text: 'Versiyon Kontrol',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Destek Yonetim
  {
    path: '/management/support/open/',
    name: 'manage-support-open',
    component: () => import('@/views/management/destek-yonetim/DestekList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Destek',
        },
        {
          text: 'Açık Talepler',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/support-detail-open/:detailId',
    name: 'manage-support-detail-open',
    component: () => import('@/views/management/destek-yonetim/destek-detail/DestekDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Destek',
        },
        {
          text: 'Açık Talepler',
          to: '/management/support/open',
        },
        {
          text: 'Destek Detay',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/support-detail-close/:detailId',
    name: 'manage-support-detail-close',
    component: () => import('@/views/management/destek-yonetim/destek-detail/DestekDetail.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Destek',
        },
        {
          text: 'Kapanan Talepler',
          to: '/management/support/closed/',
        },
        {
          text: 'Destek Detay',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/support/closed/',
    name: 'manage-support-closed',
    component: () => import('@/views/management/destek-yonetim/KapananDestekList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Destek',
        },
        {
          text: 'Kapanan Talepler',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  // Kalite Yonetim
  {
    path: '/management/kalite/cevreseletki',
    name: 'manage-cevresel-etki-list',
    component: () => import('@/views/management/kalite-yonetim/CevreselEtki/CevreselEtkiList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kalite',
        },
        {
          text: 'Çevresel Etki',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/kalite/dmaxoran',
    name: 'manage-dmax-oran-list',
    component: () => import('@/views/management/kalite-yonetim/DMaxOran/DmaxOraniList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kalite',
        },
        {
          text: 'DMAX Oranı',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/kalite/klororan',
    name: 'manage-klor-oran-list',
    component: () => import('@/views/management/kalite-yonetim/KlorOran/KlorOranList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kalite',
        },
        {
          text: 'Klor Oranı',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/kalite/numunesekli',
    name: 'manage-numune-sekli-list',
    component: () => import('@/views/management/kalite-yonetim/NumuneSekli/NumuneSekliList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kalite',
        },
        {
          text: 'Numune Şekli',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/kalite/betondayanimsinif',
    name: 'manage-beton-dayanim-sinif-list',
    component: () => import('@/views/management/kalite-yonetim/BetonDayanimSinifi/BetonDayanimSinifList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kalite',
        },
        {
          text: 'Numune Şekli',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/kalite/kirmaplanlari',
    name: 'manage-kirma-plan-list',
    component: () => import('@/views/management/kalite-yonetim/KirmaPlanlari/KirmaPlanlariList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kalite',
        },
        {
          text: 'Numune Şekli',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

  {
    path: '/management/kalite/yapielemani',
    name: 'manage-yapi-elemani-list',
    component: () => import('@/views/management/kalite-yonetim/YapiElemani/YapiElemaniList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Kalite',
        },
        {
          text: 'Yapı Elemanı',
        },
      ],
      authorize: [Role.SuperAdmin, Role.SuperOperator],
    },
  },

]

import { Role } from '@/auth/utils'

export default [
  {
    path: '/customer/home',
    name: 'customer-home',
    component: () => import('@/views/customer/Home.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
    },
  },

  // Musteri
  {
    path: '/customer/musteri',
    name: 'customer-musteri',
    component: () => import('@/views/customer/muster-customer/MusteriBilgi.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Müşteri',
        },
      ],
    },
  },

  // Teklif
  {
    path: '/customer/teklif',
    name: 'customer-teklif',
    component: () => import('@/views/customer/teklif-customer/TeklifList.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Teklifler',
        },
      ],
    },
  },

   // Teklif Detail
   {
    path: '/customer/teklif/islem/:id',
    name: 'customer-teklif-islem',
    component: () => import('@/views/customer/teklif-customer/teklif-islem/TeklifIslem.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Teklifler',
          to: '/customer/teklif',
        },
        {
          text: 'Teklif Detay İşlemleri',
        },
      ],
    },
  },

  // Odeme
  {
    path: '/customer/payment',
    name: 'customer-payment',
    component: () => import('@/views/customer/customer-payment/GeneralPayment.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Ödeme',
        },
      ],
    },
  },
  {
    path: '/customer/payment/status/:status',
    name: 'customer-payment-status',
    component: () => import('@/views/customer/customer-payment/PaymentStatus.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Ödeme',
          to: '/customer/payment/',
        },
        {
          text: 'Ödeme Durumu',
        },
      ],
    },
  },

  // Siparis
  {
    path: '/customer/siparis',
    name: 'customer-siparis',
    component: () => import('@/views/customer/siparis-customer/SiparisList.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Sipariş',
        },
      ],
    },
  },
  {
    path: '/customer/siparis/detay/:id',
    name: 'customer-siparis-detay',
    component: () => import('@/views/customer/siparis-customer/siparis-detay/SiparisDetay.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Sipariş',
          to: '/customer/siparis/',
        },
        {
          text: 'Sipariş Detay',
        },
      ],
    },
  },

  // Cari Ekstra
  {
    path: '/customer/cariekstra',
    name: 'customer-cariekstra',
    component: () => import('@/views/customer/cariekstra-customer/CariEkstraList.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Cari Ekstre',
        },
      ],
    },
  },

  // Lisans
  {
    path: '/customer/lisanssifrebilgi',
    name: 'customer-lisanssifrebilgi',
    component: () => import('@/views/customer/lisanssifrebilgi-customer/LisansSifreBilgiList.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Lisanslar',
        },
      ],
    },
  },

  // WebApi Kullanici Yönetim
  {
    path: '/customer/webapi-kullanici',
    name: 'customer-webapi-kullanici',
    component: () => import('@/views/customer/cloudkullanici-customer/web-api/WebApiKullaniciList.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Cloud Kullanıcılar',
        },
        {
          text: 'WebApi Kullanıcıları',
        },
      ],
    },
  },

  // Veritabani Kullanici
  {
    path: '/customer/db-kullanici',
    name: 'customer-db-kullanici',
    component: () => import('@/views/customer/cloudkullanici-customer/db/DBKullaniciList.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Cloud Kullanıcılar',
        },
        {
          text: 'Veri Tabanı Kullanıcıları',
        },
      ],
    },
  },

  // Destek Save
  {
    path: '/customer/support/new',
    name: 'customer-support-new',
    component: () => import('@/views/customer/support-customer/SupportSave.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Destek',
          to: '/customer/support',
        },
        {
          text: 'Yeni Destek Kaydı',
        },
      ],
    },
  },

  // Destek List
  {
    path: '/customer/support',
    name: 'customer-support-records',
    component: () => import('@/views/customer/support-customer/SupportList.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Destek',
        },
      ],
    },
  },

  // Destek Detail
  {
    path: '/customer/support/detail/:detailId',
    name: 'customer-support-detail',
    component: () => import('@/views/customer/support-customer/SupportDetail.vue'),
    meta: {
      authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      breadcrumb: [
        {
          text: 'Destek',
          to: '/customer/support',
        },
        {
          text: 'Destek Detay',
        },
      ],
    },
  },
]

import { sortCompare } from '../../api.utils'

const LisansSifreBilgiCustomerService = {
  filterLisansSifreBilgiList(list, queryParams) {
    const {
      sortBy = 'id',
      sortDesc = false,
      query = '',
      isLisansGecerli = null,
    } = queryParams
    
    const queryLowered = query.toLowerCase()
    const filteredData = list.filter(
      lisans => {
          let result = lisans.sLisansNo.toLowerCase().includes(queryLowered);
          if (lisans.kullanici) {
            result = result || lisans.kullanici.toLowerCase().includes(queryLowered);
          }
          if (lisans.urun) {
            result = result || lisans.urun.toLowerCase().includes(queryLowered);
          }
          if (isLisansGecerli !== null) {
            result = result && lisans.isLisansGecerli === isLisansGecerli;
          }
          return result;
        },
      )

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()
    return filteredData
  },
}

export default LisansSifreBilgiCustomerService

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  ticketList: [],
  ticketFilteredList: [],
  ticketFilter: {
    departmentTuru: null,
    ticketOncelikTuru: null,
    query: '',
  },
}

const helpers = {
  filterTicketList(list) {
    const queryLowered = state.ticketFilter.query.toLowerCase();
    return list.filter(ticket => (ticket.sKonu.toLowerCase().includes(queryLowered)
      && ticket.departmentTuru === (state.ticketFilter.departmentTuru || ticket.departmentTuru))
      && ticket.ticketOncelikTuru === (state.ticketFilter.ticketOncelikTuru || ticket.ticketOncelikTuru))
  },
}

const getters = {
  getTickets(state) {
    return state.ticketFilteredList
  },
  getTicketFilter(state) {
    return state.ticketFilter
  },
}

const mutations = {
  setTickets(state, ticketList) {
    state.ticketList = ticketList
    state.ticketFilteredList = helpers.filterTicketList(ticketList)
  },

  setTicketFilter(state, { key, value }) {
    state.ticketFilter[key] = value
    state.ticketFilteredList = helpers.filterTicketList(state.ticketList)
  },
}

const actions = {
  fetchTickets(context, queryParams) {
    return ApiService.get('customer/destek/ticket/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setTickets', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchTicket(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`customer/destek/ticket/get/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchTicketDetaylar(context, ticketId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`customer/destek/ticket/detaylar/${ticketId}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveTicket(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('customer/destek/ticket/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  sendAnswer(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('customer/destek/ticket/answer/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeTicket(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`customer/destek/ticket/remove/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

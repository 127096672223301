import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  satisPersonelSelectionList: [],
  satisPersonelList: [],
}

const getters = {
  getSatisPersonels(state) {
    return state.satisPersonelList
  },
}

const mutations = {
  setSelectionSatisPersonels(state, satisPersonelList) {
    state.satisPersonelSelectionList = satisPersonelList
  },
  setSatisPersonels(state, satisPersonelList) {
    state.satisPersonelList = satisPersonelList
  },
}

const actions = {
  fetchSelectionSatisPersonels(context) {
    return ApiService.get('manage/satispersonel/selection', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSelectionSatisPersonels', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchSatisPersonels(context, queryParams) {
    return ApiService.get('manage/satispersonel/getall', queryParams, this)
    .then(response => {
      if (response.statusCode === 200) {
        context.commit('setSatisPersonels', response.result)
      } else {
        AlertService.error(this._vm, response.message)
      }
    })
  },

  fetchSatisPersonel(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/satispersonel/get/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveSatisPersonel(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/satispersonel/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeSatisPersonel(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/satispersonel/remove/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'

const state = {
  dbkullaniciyetkiList: [],
}

const getters = {
  getDbKullaniciYetkis(state) {
    return state.dbkullaniciyetkiList
  },
}

const mutations = {
  setDbKullaniciYetkis(state, dbkullaniciyetkiList) {
    state.dbkullaniciyetkiList = dbkullaniciyetkiList
  },
}

const actions = {
  fetchDbKullaniciYetkis(context) {
    return ApiService.get('turyetki/dbkullanici/yetkigruplari', {}, this)
      .then(response => {
          context.commit('setDbKullaniciYetkis', response)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { apiToDate } from '@/@core/utils/filter'

const state = {
  musteriData: null,
}

const getters = {
  getMusteriData(state) {
    return state.musteriData
  },
}

const mutations = {
  setMusteriData(state, musteriData) {
    musteriData.dtOlusTarih = apiToDate(musteriData.dtOlusTarih);
    state.musteriData = musteriData
  },
}

const actions = {
  fetchMusteri(context) {
    return ApiService.get('customer/musteri/get', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setMusteriData', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

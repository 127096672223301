import { apiToDate } from '@core/utils/filter';
import StaticOptions from '@/common/options/StaticOptions';
import { sortCompare } from '../../api.utils'

const DuyuruYonetimService = {
  filterDuyuruList(list, queryParams) {
    const {
      uygulamaTipi = null,
      sortBy = 'id',
      sortDesc = false,
      query = '',
    } = queryParams
    
    const queryLowered = query.toLowerCase()
    const filteredData = list.filter(
      duyuru => {
        duyuru.sBaslik = duyuru.sBaslik ? duyuru.sBaslik : '';
        return duyuru.sBaslik.toLowerCase().includes(queryLowered)
      && duyuru.sUygulamaTuru === (uygulamaTipi || duyuru.sUygulamaTuru)
      },
    )

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()
    return sortedData
  },
  
  getUygulamaTuru(list) {
    return list.map(duyuru => {
      duyuru.sUygulamaTuru = StaticOptions.uygulamaTuruOptions.find(tur => tur.value === duyuru.sUygulamaTuru);
      duyuru.sUygulamaTuruLabel = ''
      if (duyuru.sUygulamaTuru) duyuru.sUygulamaTuruLabel = duyuru.sUygulamaTuru.label;
      duyuru.sUygulamaTuru = duyuru.sUygulamaTuru.value;
      return duyuru;
    })
  },
}

export default DuyuruYonetimService

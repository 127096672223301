import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import LisansSifreBilgiCustomerService from '@/common/custom-service/customer/LisansSifreBilgiCustomerService'

const state = {
  lisansSifreBilgiList: [],
  lisansSifreBilgiFilteredList: [],
  lisansSifreBilgiFilter: {
    sortBy: 'id',
    sortDesc: false,
    query: '',
    isLisansGecerli: null,
  },
}

const getters = {
  getLisansSifreBilgis(state) {
    return state.lisansSifreBilgiFilteredList
  },
  getLisansSifreBilgiFilters(state) {
    return state.lisansSifreBilgiFilter
  },
}

const mutations = {
  setLisansSifreBilgis(state, lisansSifreBilgiList) {
    state.lisansSifreBilgiList = lisansSifreBilgiList
    state.lisansSifreBilgiFilteredList = LisansSifreBilgiCustomerService.filterLisansSifreBilgiList(state.lisansSifreBilgiList, state.lisansSifreBilgiFilter)
  },
  setLisansSifreBilgiFilters(state, { key, value }) {
    state.lisansSifreBilgiFilter[key] = value
    state.lisansSifreBilgiFilteredList = LisansSifreBilgiCustomerService.filterLisansSifreBilgiList(state.lisansSifreBilgiList, state.lisansSifreBilgiFilter)
  },
}

const actions = {
  fetchLisansSifreBilgis(context) {
    return ApiService.get('customer/lisanssifrebilgi/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setLisansSifreBilgis', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

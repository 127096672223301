import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import AlertService from '@/common/alert.service'
import { isUserLoggedIn, getUserRole, Role, isTokenExpired } from '@/auth/utils'
import management from './routes/management'
import customer from './routes/customer'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '/',
      redirect: { name: 'manage-home' },
      meta: {
        authorize: [Role.SuperAdmin, Role.SuperOperator],
      },
    },
    ...management,

    { 
      path: '/customer', 
      redirect: { name: 'customer-home' },
      meta: {
        authorize: [Role.MusteriAdmin, Role.MusteriOperator],
      },
    },
    ...customer,

    {
      path: '/profile-settings',
      name: 'profile-settings',
      component: () => import('@/views/profile-settings/ProfileSettings.vue'),
      meta: {
        authorize: [],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/reset-password/:id',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta
  const isExpired = isTokenExpired()
  const isLoggedIn = isUserLoggedIn()
  const currentUserRole = getUserRole()
  if (authorize) {
    if (!isLoggedIn) {
      // not logged in so redirect to login page with the return url
      return next({ name: 'login' });
    }

    if (isExpired) {
      return next({ name: 'login' });
    }
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUserRole)) {
      // role not authorised so redirect to home page
      if ((currentUserRole === Role.SuperAdmin || currentUserRole === Role.SuperOperator) && to.name !== 'manage-home') {
        next({ name: 'manage-home' });
      } else if ((currentUserRole === Role.MusteriAdmin || currentUserRole === Role.MusteriOperator) && to.name !== 'customer-home') {
        next({ name: 'customer-home' });
      } else {
        next({ path: '/' });
      }
    }
  }

  return next()
})
export default router

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  bannerList: [],
  bannerFilteredList: [],
}

const getters = {
  getBanners(state) {
    return state.bannerFilteredList
  },
  getBannerFilter(state) {
    return state.bannerFilter
  },
}

const mutations = {
  setBanners(state, bannerList) {
    state.bannerList = bannerList
    state.bannerFilteredList = bannerList
  },
  setBannerFilter(state, { key, value }) {
    state.bannerFilter[key] = value
    state.bannerFilteredList = state.bannerList
  },
}

const actions = {
  fetchBanners(context, queryParams) {
    return ApiService.get('manage/banner/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setBanners', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchBanner(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/banner/get/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveBanner(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('manage/banner/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeBanner(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/banner/remove/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActiveBanner(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/banner/activetoggle/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { uyumTeklifDocDatetoJSDate, getCurrentDate, getFirstDayOfYear } from '@core/utils/filter';

const state = {
  teklifList: [],
  kapananTeklifList: [],
  teklifFilter: {
    dtBaslangic: getFirstDayOfYear(),
    dtBitis: getCurrentDate(),
    entityId: null,
    offerStatus: null,
    teklifDurumu: 0,
  },
}

const helpers = {
  mapTeklifList(responseData) {
    const dxSummaryFields = ['amtVat', 'amtWithDisc', 'amtTra', 'qty', 'amtWithDisc'];
    return responseData.result.offerList.map(item => {
      item.docDateJS = uyumTeklifDocDatetoJSDate(item.docDate)
      item.uyumDetailItem.map(detay => {
        dxSummaryFields.forEach(summary => {
          const summaryKey = `dx${summary}`;
          detay[summaryKey] = Number(detay[summary].replace(',', '.'))
        })
        detay.stockName = detay.itemNameManual ?? detay.dcardName;
        if (detay.itemNameManual === '') detay.stockName = detay.dcardName;
        return detay;
      });
      const findSatisPersonel = responseData.result.satisPersonelInfoList.find(satisPersonelItem => satisPersonelItem.sKodu === item.salesPersonCode);
      if (findSatisPersonel) {
        item.salesPersonName = findSatisPersonel.sAdSoyad;
      }

      const teklifSepet = responseData.result.sepetOfferList.find(sepetItem => sepetItem.pTeklifId === parseInt(item.id, 10));
      if (teklifSepet) {
        item.teklifDurum = teklifSepet.teklifDurumu;
      } else {
        item.teklifDurum = 0;
      }
      return item;
    });
  },
}

const getters = {
  getTeklifs(state) {
    return state.teklifList;
  },
  getTeklifFilters(state) {
    return state.teklifFilter;
  },
  getKapananTeklifs(state) {
    return state.kapananTeklifList;
  },
}

const mutations = {
  setTeklifs(state, teklifList) {
    state.teklifList = teklifList
  },
  setKapananTeklifs(state, kapananTeklifList) {
    state.kapananTeklifList = kapananTeklifList
  },
  setTeklifFilter(state, { key, value }) {
    state.teklifFilter[key] = value
  },
}

const actions = {
  fetchTeklifs({ commit, state }, queryParams) {
    if (!queryParams) {
      queryParams = state.teklifFilter;
    }
    return ApiService.post('manage/teklif/getall/erpapi', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          let list = helpers.mapTeklifList(response);
          if (queryParams.teklifDurumu != null) {
            list = list.filter(x => x.teklifDurum === queryParams.teklifDurumu);
          }
          commit('setTeklifs', list)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchKapananTeklifs(context, queryParams) {
    if (!queryParams) {
      queryParams = state.teklifFilter;
    }
    // Uyum soap service taradındaki filtre değeri Açık / Kapalı
    queryParams.offerStatus = 'Kapalı';
    return ApiService.post('manage/teklif/getall/erpapi', queryParams, this)
      .then(response => {
        queryParams.offerStatus = null;
        if (response.statusCode === 200) {
          let list = helpers.mapTeklifList(response);
          if (queryParams.teklifDurumu != null) {
            list = list.filter(x => x.teklifDurum === queryParams.teklifDurumu);
          }
          context.commit('setKapananTeklifs', list)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchTeklifSingle(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklif/get/erpapi', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchTeklifFile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.download(`htmltopdf/manage/downloadoffer/${queryParams.teklifId}/${queryParams.musteriKodu}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchTeklifDurumList(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/teklif/getall/durumgecmis/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  onayTeklif(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('manage/teklif/onay', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  teklifChangeFile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('manage/teklif/file/change', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  teklifSiparisOlustur(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklif/tamamla/erpapi', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  teklifDurumGuncelle(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklif/durum/guncelle', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchTeklifNots(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklif/not/getall', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  saveTeklifNot(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/teklif/not/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  sendTeklifFile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('htmltopdf/manage/sendoffer', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'

const state = {
  dbkullaniciturList: [],
}

const getters = {
  getDbKullaniciTurs(state) {
    return state.dbkullaniciturList
  },
}

const mutations = {
  setDbKullaniciTurs(state, dbkullaniciturList) {
    state.dbkullaniciturList = dbkullaniciturList
  },
}

const actions = {
  fetchDbKullaniciTurs(context) {
    return ApiService.get('turyetki/dbkullanici/turler', {}, this)
      .then(response => {
          context.commit('setDbKullaniciTurs', response)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

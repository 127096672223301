import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  urunGrupSelectionList: [],
  urunGrupList: [],
}

const getters = {
  getSelectionUrunGrups(state) {
    return state.urunGrupSelectionList;
  },
  getUrunGrups(state) {
    return state.urunGrupList
  },
}

const mutations = {
  setSelectionUrunGrups(state, urunGrupList) {
    state.urunGrupSelectionList = urunGrupList
  },
  setUrunGrups(state, urunGrupList) {
    state.urunGrupList = urunGrupList
  },
}

const actions = {
  fetchSelectionUrunGrups(context) {
    return ApiService.get('manage/urungrup/selection', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSelectionUrunGrups', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchUrunGrups(context, queryParams) {
    return ApiService.post('manage/urungrup/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setUrunGrups', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchUrunGrup(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/urungrup/get/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveUrunGrup(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/urungrup/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

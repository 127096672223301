import Vue from 'vue'
import Vuex from 'vuex'

// General
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import profileSettings from './profile-settings/profilesettings.module';
import dbKullaniciTurYonetim from './yetkitur-options/dbkullanicitur.module';
import dbKullaniciYetkiYonetim from './yetkitur-options/dbkullaniciyetki.module';
import loader from './app/loader.module';

// Management
import yonetimKullanici from './management/kullanici-yonetim/kullanici.module';
import duyuruYonetim from './management/duyuru-yonetim/duyuru.module';
import yonetimMusteri from './management/kullanici-yonetim/musteri.module'
import genelayarYonetim from './management/tanim-yonetim/genelayar.module';
import sozlesmeYonetim from './management/tanim-yonetim/sozlesme.module';
import bankaBilgiYonetim from './management/tanim-yonetim/bankabilgi.module';
import urunGrupYonetim from './management/tanim-yonetim/urungrup.module';
import vergidaireYonetim from './management/tanim-yonetim/vergidaire.module';
import adresYonetim from './management/tanim-yonetim/adres.module';
import urunYonetim from './management/urun-yonetim/urun.module';
import dbKullaniciYonetim from './management/dbkullanici-yonetim/dbkullanici.module';
import teklifYonetim from './management/teklif-yonetim/teklif.module';
import siparisYonetim from './management/siparis-yonetim/siparis.module';
import cariEkstraYonetim from './management/cariekstra-yonetim/cariekstra.module';
import dbInfoYonetim from './management/database-yonetim/dbInfo.module';
import sunucuYonetim from './management/database-yonetim/sunucu.module';
import lisanssifrebilgiYonetim from './management/lisanssifrebilgi-yonetim/lisanssifrebilgi.module';
import referansYonetim from './management/website-yonetim/referans.module';
import cozumOrtakYonetim from './management/website-yonetim/cozumortak.module';
import bannerYonetim from './management/website-yonetim/banner.module';
import sayfaYonetim from './management/website-yonetim/sayfa.module';
import ticketYonetim from './management/destek-yonetim/destek.module';
import teklifAciklamaYonetim from './management/tanim-yonetim/teklifaciklama.module';
import satisPersonelYonetim from './management/tanim-yonetim/satispersonel.module';
import odemeYonetim from './management/odeme-yonetim/odeme.module';
import contactFormYonetim from './management/website-yonetim/contactform.module';
import demoTalebYonetim from './management/taleb-yonetim/demoTaleb.module';
import bayiTalebYonetim from './management/taleb-yonetim/bayiTaleb.module';

// Customer
import homeCustomer from './customer/home.module';
import musteriCustomer from './customer/musteri-customer/musteri.module';
import teklifCustomer from './customer/teklif-customer/teklif.module';
import siparisCustomer from './customer/siparis-customer/siparis.module';
import lisanssifrebilgiCustomer from './customer/lisanssifrebilgi-customer/lisanssifrebilgi.module';
import cariEkstraCustomer from './customer/cariekstra-customer/cariekstra.module';
import dbInfoCustomer from './customer/database-customer/dbInfo.module';
import dbKullaniciCustomer from './customer/dbkullanici-customer/dbkullanici.module';
import loginInfoCustomer from './customer/logininfo-customer/logininfo.module';
import ticketCustomer from './customer/support-costumer/ticket.module';
import paymentCustomer from './customer/payment-customer/payment.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // General
    app,
    loader,
    appConfig,
    verticalMenu,
    profileSettings,
    dbKullaniciTurYonetim,
    dbKullaniciYetkiYonetim,

    // Management
    yonetimKullanici,
    yonetimMusteri,
    genelayarYonetim,
    sozlesmeYonetim,
    bankaBilgiYonetim,
    urunGrupYonetim,
    vergidaireYonetim,
    adresYonetim,
    urunYonetim,
    dbKullaniciYonetim,
    teklifYonetim,
    siparisYonetim,
    cariEkstraYonetim,
    duyuruYonetim,
    dbInfoYonetim,
    sunucuYonetim,
    lisanssifrebilgiYonetim,
    referansYonetim,
    cozumOrtakYonetim,
    bannerYonetim,
    sayfaYonetim,
    ticketYonetim,
    teklifAciklamaYonetim,
    satisPersonelYonetim,
    odemeYonetim,
    contactFormYonetim,
    demoTalebYonetim,
    bayiTalebYonetim,
    
    // Customer
    homeCustomer,
    musteriCustomer,
    teklifCustomer,
    siparisCustomer,
    lisanssifrebilgiCustomer,
    cariEkstraCustomer,
    dbInfoCustomer,
    dbKullaniciCustomer,
    loginInfoCustomer,
    ticketCustomer,
    paymentCustomer,
  },
  strict: process.env.DEV,
})

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  homeInitObject: {
    activeDbKullaniciSayisi: 0,
    activeWebApiKullaniciSayisi: 0,
  },
}

const getters = {
  getHomeInitObject(state) {
    return state.homeInitObject
  },
}

const mutations = {
  setHomeInitObject(state, homeInitObject) {
    state.homeInitObject = homeInitObject
  },
}

const actions = {
  fetchHomeInitObject(context) {
    return ApiService.get('customer/home/app/init', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setHomeInitObject', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

function decodeJwt(token) {
  return token && JSON.parse(atob(token.split('.')[1]))
}

export const isTokenExpired = () => {
  const jwtDecoded = decodeJwt(localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName));
  if (jwtDecoded) {
    // console.log(new Date(jwtDecoded.exp * 1000 - Date.now()).toLocaleTimeString());
    return jwtDecoded.exp * 1000 - Date.now() <= 0;
  }
  return true
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getUserRole = () => {
  if (getUserData()) {
    return getUserData().userRole;
  } 
  return null;
}

// Roles
export const Role = {
  SuperAdmin: 'SuperAdmin',
  SuperOperator: 'SuperOperator',
  MusteriAdmin: 'MusteriAdmin',
  MusteriOperator: 'MusteriOperator',
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === Role.SuperAdmin) return { name: 'manage-home' }
  if (userRole === Role.SuperOperator) return { name: 'manage-home' }
  if (userRole === Role.MusteriAdmin) return { name: 'customer-home' }
  if (userRole === Role.MusteriOperator) return { name: 'customer-home' }
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'login' }
}

import Vue from 'vue'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://localhost:5002/api/' : 'https://api.kassyazilim.com/api/',
  headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT' },
})

// Add a response interceptor
axiosIns.interceptors.response.use(response => response.data, error => Promise.reject(error))

Vue.prototype.$http = axiosIns

export default axiosIns

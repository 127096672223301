import { sortCompare } from '../../api.utils'

const DBKullaniciYonetimService = {
  filterDBKullaniciList(list, queryParams) {
    const {
      /* eslint-disable */
      dbFirmaInfo_Id = null,
      isActive = null,
      sKullaniciAdi = null,
      sortBy = 'id',
      sortDesc = false,
      query = '',
    } = queryParams
    
    const filteredData = list.filter(
      dbKullanici => {
          let result = dbKullanici.dbFirmaInfo_Id === (dbFirmaInfo_Id || dbKullanici.dbFirmaInfo_Id);
          if (sKullaniciAdi) {
            result = result && (dbKullanici.sKullaniciAdi.toLowerCase().includes(sKullaniciAdi.toLowerCase()) || dbKullanici.sAdi.toLowerCase().includes(sKullaniciAdi.toLowerCase()));
          }
          if (isActive !== null) {
            result = result && dbKullanici.isActive === isActive;
          }
          return result;
        },
      )

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()
    return sortedData
  },
}

export default DBKullaniciYonetimService

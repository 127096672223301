import ApiService from '@/common/api.service'

const state = {
  
}

const getters = {
  
}

const mutations = {
  
}

const actions = {
  fetchPaymentFrame(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('payment/customer/frame', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSiparisPaymentFrame(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('payment/customer/siparis/frame', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  CheckPaymentStatus(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`payment/customer/check/status/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { getCurrentDate, getFirstDayOfYear } from '@core/utils/filter';

const state = {
  cariEkstraList: [],
  cariEkstraFilter: {
    dtBaslangic: getFirstDayOfYear(),
    dtBitis: getCurrentDate(),
  },
}

const getters = {
  getCariEkstras(state) {
    return state.cariEkstraList
  },
  getCariEkstraFilter(state) {
    return state.cariEkstraFilter
  },
}

const mutations = {
  setCariEkstras(state, cariEkstraList) {
    state.cariEkstraList = cariEkstraList
  },
  setCariEkstraFilter(state, { key, value }) {
    state.cariEkstraFilter[key] = value
  },
}

const actions = {
  fetchCariEkstras(context, queryParams) {
    return ApiService.post('customer/cariekstra/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setCariEkstras', response.result.table1)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import { sortCompare } from '../../../api.utils'

const VergiDaireService = {
  filterVergiDaireList(list, queryParams) {
    const {
      sSaymanlikKodu,
      sortBy = 'id',
      sortDesc = false,
      query = '',
    } = queryParams

    const queryLowered = query.toLowerCase()
    const filteredData = list.filter(
      vergidaire => vergidaire.sVergiDairesiAdi.toLowerCase().includes(queryLowered)
      && vergidaire.sSaymanlikKodu.includes(sSaymanlikKodu),
    )
    
    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()
    return sortedData
  },
}

export default VergiDaireService

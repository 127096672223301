export default {

  kullaniciTipiOptions: [
    { label: 'Super Admin', value: 4 },
    { label: 'Super Operatör', value: 5 },
    { label: 'Müşteri Admin', value: 3 },
    { label: 'Müşteri Operatör', value: 6 },
  ],

  kullaniciStatusOptions: [
    { label: 'Aktif', value: true },
    { label: 'Pasif', value: false },
  ],

  musteriTipiOptions: [
    { label: 'Müşteri', value: 'Müşteri' },
    { label: 'Müşteri Tedarikçi', value: 'Müşteri_Tedarikçi' },
    { label: 'Tedarikçi', value: 'Tedarikçi' },
  ],

  urunDurumOptions: [
    { label: 'Aktif', value: true },
    { label: 'Pasif', value: false },
  ],

  urunDbDurumOptions: [
    { label: 'Veri Tabanı Aktif', value: true },
    { label: 'Veri Tabanı Pasif', value: false },
  ],

  urunShowWebSiteDurumOptions: [
    { label: 'Web Sitesinde Göster', value: true },
    { label: 'Web Sitesinde Gösterme', value: false },
  ],

  urunGrupModulOptions: [
    { label: 'Tır Kantar', value: 'tirkantar' },
    { label: 'Beton', value: 'beton' },
    { label: 'Akaryakıt', value: 'akaryakit' },
    { label: 'Araç Takip', value: 'aractakip' },
    { label: 'ERP', value: 'erp' },
    { label: 'E-İrsaliye', value: 'eirsaliye' },
  ],

  urunGrupTurOptions: [
    { label: 'Ürün', value: 'urun' },
    { label: 'Hizmet', value: 'hizmet' },
  ],

  uygulamaTuruOptions: [
    { label: 'E-Irsaliye Connector', value: 'irsaliye' },
    { label: 'Kantar', value: 'kantar' },
    { label: 'Tesislerim', value: 'tesislerim' },
  ],

  urunResimTurOptions: [
    { label: 'Seçilmedi', value: 0 },
    { label: 'Logo', value: 1 },
    { label: 'Arkaplan', value: 2 },
    { label: 'Galeri', value: 3 },
  ],

  webApiKullaniciDurumOptions: [
    { label: 'Aktif', value: true },
    { label: 'Pasif', value: false },
  ],

  lisansSifreBilgiDurumOptions: [
    { label: 'Geçerli', value: true },
    { label: 'Geçersiz', value: false },
  ],

  dilOptions: [
    { label: 'Türkçe', value: 'tr' },
    { label: 'İngilizce', value: 'en' },
  ],

  webSiteSayfaTurOptions: [
    { label: 'Blog', value: 1 },
    { label: 'Gizli Sayfa', value: 2 },
  ],

  departmentTuruOptions: [
    { label: 'Teknik', value: 1 },
    { label: 'Satış', value: 2 },
    { label: 'Muhasebe', value: 3 },
    { label: 'Yönetim', value: 4 },
  ],

  ticketOncelikTuruOptions: [
    { label: 'Acil', value: 1 },
    { label: 'Normal', value: 2 },
  ],

  teklifAciklamaTuruOptions: [
    { label: 'Genel Açıklama', value: 1 },
    { label: 'Ürün Açıklama', value: 2 },
  ],

  teklifDurum: [
    { label: 'Beklemede', value: 0 },
    { label: 'Onaylandı', value: 1 },
    { label: 'Sipariş Oluşturuldu', value: 2 },
    { label: 'Reddedildi', value: 3 },
  ],

  ticketUygulamaTuruOptions: [
    { label: 'Seçilmedi', value: 0 },
    { label: 'Sistem', value: 1 },
    { label: 'Tesislerim', value: 2 },
    { label: 'Kantar', value: 3 },
    { label: 'E-Irsaliye', value: 4 },
    { label: 'Beton Otomasyon', value: 5 },
  ],

  siparisOdemeSekilOptions: [
    { label: 'Belirtilmemiş', value: 0 },
    { label: 'Nakit', value: 1 },
    { label: 'Kredi Kartı', value: 2 },
    { label: 'Havale', value: 3 },
    { label: 'Havale Bekliyor', value: 4 },
    { label: 'Çek', value: 5 },
    { label: 'Cari Hesap', value: 6 },
  ],

  siparisDurumlari: [
    { desc: 'Lütfen ödeme işlemine geçiniz.', variant: '', label: 'Beklemede', value: 0 },
    { desc: 'Lütfen onaylama işlemine geçiniz.', variant: 'primary', label: 'Ödeme Yapıldı', value: 1 },
    { desc: 'Lütfen kurulum işlemine geçiniz.', variant: 'success', label: 'Onaylandı', value: 2 },
    { desc: 'Lütfen kapatma işlemine geçiniz.', variant: 'info', label: 'Kurulum Yapıldı', value: 3 },
    { desc: '', variant: 'warning', label: 'Kapatıldı', value: 4 },
    { desc: '', variant: 'danger', label: 'Reddedildi', value: 5 },
  ],

  numuneSekliOptions: [
    { label: 'Küp', value: 1 },
    { label: 'Silindir', value: 2 },
  ],

  appPlatformOptions: [
    { text: 'Android', value: 0 },
    { text: 'iOS', value: 1 },
  ],

  talebDurumOptions: [
    { label: 'Beklemede', value: 0 },
    { label: 'Görüşüldü', value: 1 },
  ],
}

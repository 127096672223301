import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import KullaniciYonetimService from '@/common/custom-service/management/KullaniciYonetimService'

const state = {
  kullaniciList: [],
  personelList: [],
  musteriKullaniciList: [],
  kullaniciFilteredList: [],
  kullaniciLocalFilter: {
    kullaniciTipi: null,
    musteriId: null,
    sKullaniciKodu: null,
    isActive: null,
    sortBy: 'id',
    sortDesc: false,
    query: '',
  },
}

const getters = {
  getKullanicis(state) {
    return state.kullaniciFilteredList
  },

  getPersonels(state) {
    return state.personelList
  },

  getMusteriKullanicis(state) {
    return state.musteriKullaniciList
  },

  getKullaniciLocalFilters(state) {
    return state.kullaniciLocalFilter
  },
}

const mutations = {
  setKullanicis(state, kullaniciList) {
    state.kullaniciList = kullaniciList
    state.kullaniciFilteredList = KullaniciYonetimService.filterKullaniciList(kullaniciList, state.kullaniciLocalFilter)
  },

  setPersonels(state, personelList) {
    state.personelList = personelList
  },

  setMusteriKullanicis(state, musteriKullaniciList) {
    state.musteriKullaniciList = musteriKullaniciList
  },

  setKullaniciFilter(state, { key, value }) {
    state.kullaniciLocalFilter[key] = value
    state.kullaniciFilteredList = KullaniciYonetimService.filterKullaniciList(state.kullaniciList, state.kullaniciLocalFilter)
  },
  refreshKullaniciFilter(state, filterParams) {
    Object.keys(state.kullaniciLocalFilter).forEach(key => {
      if (filterParams[key]) {
        state.kullaniciLocalFilter[key] = filterParams[key]
      } else if (key === 'sortBy') {
          state.kullaniciLocalFilter[key] = 'id'
        } else if (key === 'query') {
          state.kullaniciLocalFilter[key] = ''
        } else {
          state.kullaniciLocalFilter[key] = null
        }
    });
  },
}

const actions = {
  fetchKullanicis(context, queryParams) {
    return ApiService.post('manage/kullanicihesap/kullanici/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setKullanicis', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchPersonels(context, queryParams) {
    return ApiService.get('manage/kullanicihesap/personel/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setPersonels', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchMusteriKullanicis(context, queryParams) {
    return ApiService.get('manage/kullanicihesap/musteri/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setMusteriKullanicis', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchKullanici(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/kullanicihesap/kullanici/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveKullanici(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/kullanicihesap/kullanici/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { getCurrentDate } from '@core/utils/filter';

const state = {
  logList: [],
  logFilteredList: [],
  lisansSifreBilgiList: [],
  lisansSifreBilgiFilteredList: [],
  lisansSifreBilgiFilter: {
    dtLisansBitisBaslangic: null,
    dtLisansBitisBitis: null,
    kullaniciId: null,
    musteriId: null,
    isLisansGecerli: null,
    query: '',
  },
  logFilter: {
    dtLogBaslangic: getCurrentDate({ type: 'd', value: -1 }),
    dtLogBitis: getCurrentDate(),
    query: '',
  },
}

const helpers = {
  filterLogList: x => {
      let result = true;
      const q = state.logFilter.query.toLowerCase();
      const from = new Date(new Date(state.logFilter.dtLogBaslangic).setHours(0, 0)).getTime();
      const to = new Date(new Date(state.logFilter.dtLogBitis).setHours(23, 59)).getTime();
      const timestamp = new Date(x.dtTimeStamp).getTime();
     
      if (state.logFilter.dtLogBaslangic && state.logFilter.dtLogBitis) {
        result = result && (timestamp <= to && timestamp >= from)
      }
      if (q) {
        result = result && (x.sLisansNo).toLocaleLowerCase().includes(q)
        result = result || (x.sProgamAdi).toLocaleLowerCase().includes(q)
        result = result || (x.sProgamVersion).toLocaleLowerCase().includes(q)
        result = result || (x.sIpAdres).toLocaleLowerCase().includes(q)
        result = result || (x.sMakinaKodu).toLocaleLowerCase().includes(q)
        result = result || (x.sVkn).toLocaleLowerCase().includes(q)
        result = result || (x.sFirmaAdi).toLocaleLowerCase().includes(q)
      }
      return result;
  },
  filterList: x => {
    let result = true;
    const q = state.lisansSifreBilgiFilter.query.toLowerCase();
    result = result && (x.sLisansNo).toLowerCase().includes(q)
    result = result || (x.kullanici).toLowerCase().includes(q)
    result = result || (x.urun).toLowerCase().includes(q)
    result = result || (x.musteri).toLowerCase().includes(q)
    if (state.lisansSifreBilgiFilter.isLisansGecerli !== null) {
      result = result && x.isLisansGecerli === state.lisansSifreBilgiFilter.isLisansGecerli
    }
    return result;
},
}

const getters = {
  getLisansSifreBilgis(state) {
    const list = state.lisansSifreBilgiFilteredList;
    const musteriList = Array.from(new Set(list.map(x => x.musteri)));
    const groupedByMusteri = musteriList.map(musteri => {
      const users = list.filter(x => x.musteri === musteri);
      return { musteri, users, currentPage: 1 };
    })
    return groupedByMusteri
  },
  getLogs(state) {
    return state.logFilteredList
  },
  getLisansSifreBilgiFilters(state) {
    return state.lisansSifreBilgiFilter
  },
  getLogFilters(state) {
    return state.logFilter
  },
}

const mutations = {
  setLogs(state, logList) {
    state.logList = logList
    state.logFilteredList = state.logList.filter(helpers.filterLogList)
  },
  setLisansSifreBilgis(state, lisansSifreBilgiList) {
    state.lisansSifreBilgiList = lisansSifreBilgiList
    state.lisansSifreBilgiFilteredList = state.lisansSifreBilgiList.filter(helpers.filterList)
  },
  setLisansSifreBilgiFilters(state, { key, value }) {
    state.lisansSifreBilgiFilter[key] = value
    state.lisansSifreBilgiFilteredList = state.lisansSifreBilgiList.filter(helpers.filterList)
  },
  setLogFilters(state, { key, value }) {
    state.logFilter[key] = value
    state.logFilteredList = state.logList.filter(helpers.filterLogList)
  },
}

const actions = {
  fetchLisansSifreBilgis(context, queryParams) {
    return ApiService.post('manage/lisanssifrebilgi/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setLisansSifreBilgis', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchLogs(context) {
    return ApiService.post('manage/lisanssifrebilgi/logs/getall', state.logFilter, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setLogs', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchLisansSifreBilgi(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/lisanssifrebilgi/get/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  updateLisansSifreBilgi(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/lisanssifrebilgi/update', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  deleteLicenseAuth(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post(`manage/lisanssifrebilgi/auth/delete/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

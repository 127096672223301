import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import { uyumTeklifDocDatetoJSDate } from '@core/utils/filter';

const state = {
  teklifList: [],
}

const helpers = {
  mapTeklifList(responseData) {
    const dxSummaryFields = ['amtVat', 'amtWithDisc', 'amtTra', 'qty', 'amtWithDisc'];
    return responseData.result.offerList.map(item => {
      item.docDateJS = uyumTeklifDocDatetoJSDate(item.docDate)
      item.uyumDetailItem.map(detay => {
        dxSummaryFields.forEach(summary => {
          const summaryKey = `dx${summary}`;
          detay[summaryKey] = Number(detay[summary].replace(',', '.'))
        })
        detay.stockName = detay.itemNameManual ?? detay.dcardName;
        if (detay.itemNameManual === '') detay.stockName = detay.dcardName;
        return detay;
      });
      const findSatisPersonel = responseData.result.satisPersonelInfoList.find(satisPersonelItem => satisPersonelItem.sKodu === item.salesPersonCode);
      if (findSatisPersonel) {
        item.salesPersonName = findSatisPersonel.sAdSoyad;
      }

      const teklifSepet = responseData.result.sepetOfferList.find(sepetItem => sepetItem.pTeklifId === parseInt(item.id, 10));
      if (teklifSepet) {
        item.teklifDurum = teklifSepet.teklifDurumu;
      } else {
        item.teklifDurum = 0;
      }
      return item;
    });
  },
}

const getters = {
  getTeklifs(state) {
    return state.teklifList;
  },
}

const mutations = {
  setTeklifs(state, teklifList) {
    state.teklifList = teklifList
  },
}

const actions = {
  fetchTeklifs(context) {
    return ApiService.get('customer/teklif/getall/erpapi', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setTeklifs', helpers.mapTeklifList(response))
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
  fetchTeklifSingle(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('customer/teklif/get/erpapi', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchTeklifFile(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.download(`htmltopdf/customer/downloadoffer/${queryParams}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  onayTeklif(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('customer/teklif/onay', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  redTeklif(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('customer/teklif/red', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

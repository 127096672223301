import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  dbFirmaInfoList: [],
}

const getters = {
  getDbFirmaInfos(state) {
    return state.dbFirmaInfoList
  },
}

const mutations = {
  setDbFirmaInfos(state, dbFirmaInfoList) {
    state.dbFirmaInfoList = dbFirmaInfoList
  },
}

const actions = {
  fetchDbFirmaInfos(context) {
    return ApiService.get('customer/dbinfo/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setDbFirmaInfos', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

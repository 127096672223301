const AlertService = {
  success(instance, message, callback) {
    if (!message) {
      message = ''
    }
    
    instance.$swal({
      title: 'İşlem Başarılı!',
      text: message,
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }).then(confirm => {
      if (typeof callback === 'function' && confirm) {
        callback()
      }
    })
  },

  error(instance, message, callback) {
    if (!message) {
      message = ''
    }
    
    instance.$swal({
      title: 'Hata Oluştu!',
      text: message,
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }).then(confirm => {
      if (typeof callback === 'function' && confirm) {
        callback()
      }
    })
  },

  warning(instance, message, callback) {
    if (!message) {
      message = ''
    }
    
    instance.$swal({
      title: 'Uyarı...!',
      text: message,
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }).then(confirm => {
      if (typeof callback === 'function' && confirm) {
        callback()
      }
    })
  },

  confirmDelete(instance, callback, message) {
    if (!message) {
      message = 'Bu işlem kaydı silmektedir geri alamayacaksınız!'
    }

    instance.$swal({
      title: 'Emin misiniz?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, Sil!',
      cancelButtonText: 'Vazgeç',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (typeof callback === 'function' && result.value) {
        callback()
      }
    })
  },

  confirmSuccess(instance, callback, message) {
    if (!message) {
      message = ''
    }

    instance.$swal({
      title: 'İşlemi Onaylıyormusunuz?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Tamam, Devam Et',
      cancelButtonText: 'Vazgeç',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (typeof callback === 'function' && result.value) {
        callback()
      }
    })
  },
}

export default AlertService

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import VergiDaireService from '@/common/custom-service/management/tanim/VergiDaireService'

const state = {
  vergiDaireList: [],
  vergiDaireFilteredList: [],
  vergiDaireLocalFilter: {
    sSaymanlikKodu: '',
    sortBy: 'id',
    sortDesc: false,
    query: '',
  },
}

const getters = {
  getVergiDaires(state) {
    return state.vergiDaireFilteredList
  },
  getVergiDaireLocalFilters(state) {
    return state.vergiDaireLocalFilter
  },
}

const mutations = {
  setVergiDaires(state, vergiDaireList) {
    state.vergiDaireList = vergiDaireList
    state.vergiDaireFilteredList = VergiDaireService.filterVergiDaireList(vergiDaireList, state.vergiDaireLocalFilter)
  },

  setVergiDaireFilter(state, { key, value }) {
    state.vergiDaireLocalFilter[key] = value
    state.vergiDaireFilteredList = VergiDaireService.filterVergiDaireList(state.vergiDaireList, state.vergiDaireLocalFilter)
  },
}

const actions = {
  fetchVergiDaires(context, queryParams) {
    return ApiService.get('manage/vergidaire/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setVergiDaires', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchVergiDaire(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get('manage/vergidaire/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveVergiDaire(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/vergidaire/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import KullaniciYonetimService from '@/common/custom-service/management/KullaniciYonetimService'

const state = {
  musteriSelectionList: [],
  musteriList: [],
  musteriFilteredList: [],
  musteriLocalFilter: {
    pMusteriTipi: null,
    sortBy: 'id',
    sortDesc: false,
    query: '',
  },
}

const getters = {
  getSelectionMusteris(state) {
    return state.musteriSelectionList;
  },
  getMusteris(state) {
    return state.musteriFilteredList
  },
  getMusteriLocalFilters(state) {
    return state.musteriLocalFilter
  },
}

const mutations = {
  setSelectionMusteris(state, musteriList) {
    state.musteriSelectionList = musteriList
  },
  setMusteris(state, musteriList) {
    state.musteriList = musteriList
    state.musteriFilteredList = KullaniciYonetimService.filterMusteriList(musteriList, state.musteriLocalFilter)
  },
  setMusteriFilter(state, { key, value }) {
    state.musteriLocalFilter[key] = value
    state.musteriFilteredList = KullaniciYonetimService.filterMusteriList(state.musteriList, state.musteriLocalFilter)
  },
}

const actions = {
  fetchSelectionMusteris(context) {
    return ApiService.get('manage/kullanicihesap/musteri/selection', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSelectionMusteris', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchMusteris(context, queryParams) {
    return ApiService.post('manage/kullanicihesap/musteri/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setMusteris', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  refreshMusterisFromApi(context) {
    return new Promise((resolve, reject) => {
      ApiService.get('manage/kullanicihesap/musteri/download/api', {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchMusteri(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/kullanicihesap/musteri/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchMusteriContactInfo(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/kullanicihesap/musteri/contact/get', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  ulkeList: [],
  sehirList: [],
  semtList: [],
  adresLocalFilter: {
    sUlkeGuid: '',
    sSehirGuid: '',
    sSehirAdi: '',
    sIlceAdi: '',
    sSemtAdi: '',
    query: '',
  },
}

const getters = {
  getUlkeList(state) {
    return state.ulkeList
  },
  getSehirList(state) {
    return state.sehirList
  },
  getSemtList(state) {
    return state.semtList
  },
  getAdresLocalFilters(state) {
    return state.adresLocalFilter
  },
}

const mutations = {
  setUlkeler(state, adresList) {
    state.ulkeList = adresList
  },

  setSehirler(state, adresList) {
    state.sehirList = adresList
  },

  setSemtler(state, adresList) {
    state.semtList = adresList
  },

  setAdresFilter(state, { key, value }) {
    state.adresLocalFilter[key] = value
  },
}

const actions = {
  fetchUlkeler(context, queryParams) {
    return ApiService.get('manage/adres/ulke/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setUlkeler', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchSehirler(context, queryParams) {
    return ApiService.get(`manage/adres/sehir/getall/${queryParams}`, {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSehirler', response.result)
          context.commit('setSemtler', [])
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchSemtler(context, queryParams) {
    return ApiService.get(`manage/adres/semt/getall/${queryParams}`, {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSemtler', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  saveUlke(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/adres/ulke/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  saveSehir(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/adres/sehir/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  saveSemt(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/adres/semt/save/', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

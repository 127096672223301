import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  latestLoginInfoList: [],
}

const getters = {
  getLatestLoginInfos(state) {
    return state.latestLoginInfoList
  },
}

const mutations = {
  setLatestLoginInfos(state, latestLoginInfoList) {
    state.latestLoginInfoList = latestLoginInfoList
  },
}

const actions = {
  fetchLatestLoginInfos(context) {
    return ApiService.get('customer/logininfo/latest', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setLatestLoginInfos', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

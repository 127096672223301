import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  sunucuList: [],
}

const getters = {
  getSunucus(state) {
    return state.sunucuList
  },
}

const mutations = {
  setSunucus(state, sunucuList) {
    state.sunucuList = sunucuList
  },
}

const actions = {
  fetchSunucus(context) {
    return ApiService.get('manage/sunucu/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setSunucus', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  saveSunucus(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.post('manage/sunucu/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

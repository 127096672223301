import ApiService from '@/common/api.service'
import StaticOptions from '@/common/options/StaticOptions'
import { sortCompare } from '../../api.utils'

const KullaniciYonetimService = {
  filterKullaniciList(list, queryParams) {
    const {
      kullaniciTipi = null,
      sKullaniciKodu = null,
      isActive = null,
      musteriId = null,
      sortBy = 'id',
      sortDesc = false,
      query = '',
    } = queryParams
    const queryLowered = query.toLowerCase()
    const filteredData = list.filter(
      kullanici => {
        let result = (kullanici.sKullaniciKodu.toLowerCase().includes(queryLowered) || (`${kullanici.sAd} ${kullanici.sSoyad}`).toLowerCase().includes(queryLowered))
        if (kullaniciTipi !== null) {
          result = result && kullanici.kullaniciTipi === kullaniciTipi;
        }

        if (isActive !== null) {
          result = result && kullanici.isActive === isActive;
        }

        if (sKullaniciKodu !== null) {
          result = result && kullanici.sKullaniciKodu === sKullaniciKodu;
        }

        if (musteriId !== null) {
          result = result && kullanici.musteri_Id === musteriId;
        }

        return result;
      },
    )
    
    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()
    return sortedData
  },

  findKullaniciTipiNam(tipi) {
    const kullaniciTipi = StaticOptions.kullaniciTipiOptions.find(value => value.value === tipi)
    if (kullaniciTipi) {
      return kullaniciTipi.label
    }

    return ''
  },
  
  filterMusteriList(list, queryParams) {
    const {
      pMusteriTipi = null,
      sortBy = 'id',
      sortDesc = false,
      query = '',
    } = queryParams
    
    const queryLowered = query.toLowerCase()
    const filteredData = list.filter(
      musteri => {
        musteri.sMusteriKodu = (musteri.sMusteriKodu) ? musteri.sMusteriKodu : '';
        musteri.sMusteriAdi = (musteri.sMusteriAdi) ? musteri.sMusteriAdi : '';
        musteri.sVergiNo = (musteri.sVergiNo) ? musteri.sVergiNo : '';
        musteri.sTcNo = (musteri.sTcNo) ? musteri.sTcNo : '';
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        return (musteri.sMusteriKodu.toLowerCase().includes(queryLowered) || 
                musteri.sMusteriAdi.toLowerCase().includes(queryLowered) ||
                musteri.sVergiNo.toLowerCase().includes(queryLowered) ||
                musteri.sTcNo.toLowerCase().includes(queryLowered)) &&
                musteri.pMusteriTipi === (pMusteriTipi || musteri.pMusteriTipi);
      },
    )
    
    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()
    return sortedData
  },

  generate() {
    const pLength = 6;
    const keyListAlpha = 'abcdefghijklmnopqrstuvwxyz';
    const upperKeyListAlpha = keyListAlpha.toUpperCase();
    const keyListInt = '123456789';
    const keyListSpec = '!@#_';
    let password = '';
    let len = Math.ceil(pLength / 2);
    len -= 1;
    const lenSpec = pLength - 2 * len;

    for (let i = 0; i < len; i += 1) {
      password += upperKeyListAlpha.charAt(Math.floor(Math.random() * upperKeyListAlpha.length));
      password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
      password += keyListInt.charAt(Math.floor(Math.random() * keyListInt.length));
    }

    for (let i = 0; i < lenSpec; i += 1) {
      password += keyListSpec.charAt(Math.floor(Math.random() * keyListSpec.length));
    }

    password = password.split('').sort(() => 0.5 - Math.random()).join('');
    this.kullaniciData.sParola = password
  },

  sendEmail(kullaniciData, instance) {
    const {
      sAd,
      sSoyad,
      sEMail,
      sParola,
    } = kullaniciData
    const creds = {
      sAd,
      sSoyad,
      sEMail,
      sParola, 
    }
    return ApiService.post('contact/sendemail/usercred', creds, instance);
  },
}

export default KullaniciYonetimService

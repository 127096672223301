import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  cozumOrtakList: [],
  cozumOrtakFilteredList: [],
}

const getters = {
  getCozumOrtaks(state) {
    return state.cozumOrtakFilteredList
  },
  getCozumOrtakFilter(state) {
    return state.cozumOrtakFilter
  },
}

const mutations = {
  setCozumOrtaks(state, cozumOrtakList) {
    state.cozumOrtakList = cozumOrtakList
    state.cozumOrtakFilteredList = cozumOrtakList
  },
  setCozumOrtakFilter(state, { key, value }) {
    state.cozumOrtakFilter[key] = value
    state.cozumOrtakFilteredList = state.cozumOrtakList
  },
}

const actions = {
  fetchCozumOrtaks(context, queryParams) {
    return ApiService.get('manage/cozumortak/getall', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setCozumOrtaks', response.result)
        } else {
          AlertService.error(this._vm, response.message)
        }
      })
  },

  fetchCozumOrtak(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/cozumortak/get/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveCozumOrtak(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.upload('manage/cozumortak/save', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeCozumOrtak(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/cozumortak/remove/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  toggleActiveCozumOrtak(context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`manage/cozumortak/activetoggle/${id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
